<template>
  <div class="publish-video">发布视频</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.publish-video {
}
</style>
